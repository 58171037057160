import React from "react";

import "../css/contents_v1.css";
import "../css/contents.css";
import "../css/fullPage.css";
import "../css/jquery.modal.min.css";
import "../css/reset.css";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="btn_top pro">
          <a href="#secondPage">TOP</a>
        </div>
        <div className="btn_top">
          <a href="#" id="btn_top">
            TOP
          </a>
        </div>
        <div className="footer_in">
          <div className="logo">FarmOs</div>
          {/* <ul className="ft_link">
				<li><a href="#">이용약관</a></li>
				<li><a href="#">개인정보처리방침</a></li>
			</ul> */}
          <h2>COMPANY INFO</h2>
          <dl className="info_txt1">
            <dt>주소</dt>
            <dd>
              경기도 안양시 동안구 시민대로248번길 25, 503호 (관양동, 경기창조산업안양센터) <span>주식회사 파모스</span>
            </dd>
          </dl>
          <dl className="info_txt2">
            <dt>대표전화</dt>
            <dd>031-360-4152</dd>
            <dt>팩스</dt>
            <dd>0507-1798-8220</dd>
            <dt>메일</dt>
            <dd>mail@farmos.co.kr</dd>
          </dl>
          <p className="copy">Copyright 2022 FarmOS all rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
