import React from "react";
import styled from "styled-components";

interface IState {
  total: number | undefined;
  limit: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Button = styled.button`
  border: none;
  background-color: #ffffff;
  z-index: 2;
  display: inline-block;
  min-width: 27px;
  height: 18px;
  padding: 0 6px;
  line-height: 20px;
  color: #000;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  margin: 0 4px;
  text-decoration: none;
  font-family: "NotoKr", sans-serif;
  &:hover,
  &:focus,
  strong {
    position: relative;
    font-weight: 500;
    text-decoration: none;
    color: #0897fe;
    display: inline-block;
    min-width: 27px;
    height: 18px;
    padding: 0 6px;
    line-height: 18px;
    font-size: 17px;
    vertical-align: middle;
  }
`;

const Pagination = ({ total, limit, page, setPage }: IState) => {
  const numPages = Math.ceil((total as number) / limit);
  return (
    <>
      <div className="paging pc">
        <button
          onClick={() => setPage(1)}
          disabled={page === 1}
          className="first"
          style={{ border: "none" }}
        >
          첫 페이지
        </button>
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          className="prev"
          style={{ border: "none" }}
        >
          이전 페이지
        </button>
        {Array(numPages)
          .fill(undefined)
          .map((_, i) => (
            <Button
              key={i + 1}
              onClick={() => setPage(i + 1)}
              // aria-current={page === i + 1 ? "page" : null}
            >
              {i + 1}
            </Button>
          ))}
        <button
          onClick={() => setPage(page + 1)}
          disabled={page === numPages}
          className="next"
          style={{ border: "none" }}
        >
          다음 페이지
        </button>
        <button
          onClick={() => setPage(numPages)}
          disabled={page === numPages}
          className="last"
          style={{ border: "none" }}
        >
          마지막 페이지
        </button>
      </div>
    </>
  );
};

export default Pagination;
