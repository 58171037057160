import React, { useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router";
import { useQuery } from "react-query";
import { fetchDelText, fetchIdNoticeBoard, fetchViewText } from "./api";
import { isLoginAtom } from "../atoms";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import styled from "styled-components";
import { IFetch } from "../ts/Interface";


interface IData {
  success: boolean;
}

const Containor = styled.div`
  width: 100vw;
  height: 80vh;
  padding: 100px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoticeUser = () => {
  const { notice_list, id } = useParams();
  const isLogin = useRecoilValue(isLoginAtom);
  const navigation = useNavigate();
  const { refetch: countFetch } = useQuery<IData>(
    ["count"],
    () => fetchViewText(idFetch![0].text_id),
    {
      enabled: false,
    }
  );
  const { data: idFetch, isLoading } = useQuery<IFetch[]>(
    ["get", id],
    () => fetchIdNoticeBoard(Number(id)),
    {}
  );

  const {
    data,
    remove,
    refetch: delFetch,
  } = useQuery<IData>(["del"], () => fetchDelText(idFetch![0].text_id), {
    enabled: false,
  });

  useEffect(() => {
    if (idFetch !== undefined) {
      countFetch();
    }
  }, [isLoading]);

  const backHistory = () => {
    navigation("/notice_list");
  };

  const goTextEdit = () => {
    navigation("edit", { state: { item: idFetch![0], board_id: 1 } });
  };

  const delText = () => {
    Swal.fire({
      icon: "warning",
      title: "삭제 확인",
      text: "게시글을 삭제하시겠습니까?",
      showCancelButton: true,
    }).then(e => {
      if (e.isConfirmed) {
        delFetch();
      }
    });
  };

  useEffect(() => {
    if (data?.success === true) {
      Swal.fire({
        icon: "success",
        title: "삭제 성공",
        text: "게시글을 삭제하였습니다.",
      }).then(e => {
        if (e.isConfirmed) {
          remove();
          backHistory();
        }
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <Containor>
        <ReactLoading
          type="spokes"
          color="rgb(53, 126, 221)"
          height={"3%"}
          width={"3%"}
        />
      </Containor>
    );
  }

  return (
    <>
      <div id="container" className="container">
        <div id="contents">
          <div className="notice_box">
            <h2 className="title">
              <span>{idFetch![0].board_name}</span>
            </h2>
            <div className="notice_view2">
              <dl className="t1">
                <dt>작성자</dt>
                <dd>{idFetch![0].user_id}</dd>
              </dl>
              <dl className="t2">
                <dt>작성일</dt>
                <dd>
                  {moment(idFetch![0].update).format("YYYY-MM-DD HH:mm:ss")}
                </dd>
              </dl>
              <dl className="t3">
                <dt>제목</dt>
                <dd>
                  <span>[{idFetch![0].type_name}]</span>{" "}
                  {idFetch![0].text_title}
                </dd>
              </dl>
              <dl className="t4">
                <dt>내용</dt>
                <dd
                  className="ck-editor__editable_inline edit_view"
                  style={{ lineHeight: "30px" }}
                  dangerouslySetInnerHTML={{
                    __html: idFetch![0].text_content,
                  }}
                ></dd>
              </dl>
              <dl className="t5">
                <dt>첨부파일</dt>
                <dd>
                  {idFetch![0].path !== null ? (
                    <a
                      href={`/upload/${idFetch![0].path}`}
                      download={idFetch![0].path}
                    >
                      {idFetch![0].path}
                    </a>
                  ) : null}
                </dd>
              </dl>
            </div>
            <div className="btn_center">
              {isLogin.user === idFetch![0].user_id ? (
                <>
                  <button onClick={delText} className="btn st2">
                    공지사항 삭제
                  </button>
                  <button onClick={goTextEdit} className="btn st3">
                    공지사항 수정
                  </button>
                </>
              ) : null}
              <button onClick={backHistory} className="btn">
                목록
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeUser;
