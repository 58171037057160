import { IEmail, LoginInfo } from "../ts/Interface";

const BASE_URL = "https://farmos.co.kr:8081";
// const BASE_URL = "http://192.168.0.30:8081";

// user 로그인
export function fetchLogin(login: LoginInfo) {
  return fetch(`${BASE_URL}/user/login`, {
    method: "put",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ user_id: login.user_id, password: login.password }),
  }).then(response => response.json());
}

// 게시글 작성하기
export function fetchPostText(data: any) {
  return fetch(`${BASE_URL}/text`, {
    method: "post",
    body: data,
  }).then(response => response.json());
}

// 게시글 수정하기
export function fetchPutText(text_id: number | undefined, data: any) {
  return fetch(`${BASE_URL}/text/${text_id}`, {
    method: "put",
    body: data,
  }).then(response => response.json());
}

// 게시글 삭제하기
export function fetchDelText(text_id: number | undefined) {
  return fetch(`${BASE_URL}/text/${text_id}`, {
    method: "delete",
    headers: { "Content-Type": "application/json" },
  }).then(response => response.json());
}

// 조회 카운트 + 1 때리기
export function fetchViewText(id: number) {
  return fetch(`${BASE_URL}/text/view/${id}`, {
    method: "put",
    headers: { "Content-Type": "application/json" },
  }).then(response => response.json());
}

// 모든 게시글 가져오기
export function fetchNoticeBoard() {
  return fetch(`${BASE_URL}/notice/board`).then(response => response.json());
}

// 모든 게시글 가져오기
export function fetchIdNoticeBoard(id: number) {
  return fetch(`${BASE_URL}/notice/board/${id}`).then(response => response.json());
}

// mail 문의하기
export function fetchSendEmail(email: IEmail) {
  return fetch(`${BASE_URL}/asking/mail`, {
    method: "put",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(email),
  }).then(response => response.json());
}
