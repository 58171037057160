import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isModalAtom } from "../atoms";
import { isBrowser, isMobile, isTablet } from "react-device-detect";

const Header = (): JSX.Element => {
  const [isModal, setModal] = useRecoilState(isModalAtom);

  const [ismenu, setIsmenu] = useState(false);

  useEffect(() => {
    isMobile ? setIsmenu(false) : setIsmenu(true);
  }, [isMobile]);

  useEffect(() => {
    isBrowser ? setIsmenu(true) : setIsmenu(false);
    window.innerWidth <= 1280 ? setIsmenu(false) : setIsmenu(true);
  }, [isBrowser]);

  const modalShow = () => {
    setModal(!isModal);
  };

  return (
    <>
      <div id="header">
        <h1 className="logo">
          <a href="/">FarmOS</a>
        </h1>
        {ismenu ? (
          <div id="close_mn">
            <a onClick={() => setIsmenu(false)} href="#">
              메뉴 닫기
            </a>
          </div>
        ) : (
          <div id="open_mn">
            <a onClick={() => setIsmenu(true)} href="#gnb">
              메뉴 열기
            </a>
          </div>
        )}
        <nav
          id="gnb"
          style={{
            right: isMobile ? (ismenu ? "0px" : "-1300px") : 0 || isBrowser ? (ismenu ? "0px" : "-1300px") : 0,
            transition: isMobile ? "all ease 0.7s" : "none",
          }}
        >
          <div className="util">
            <ul>
              <li className="youtube">
                <a href="https://www.youtube.com/channel/UCR7vXYyNrKK1I2juiOuu4vQ" target="_blank" rel="noreferrer">
                  youtube
                </a>
              </li>
              <li className="blog">
                <a href="https://blog.naver.com/farminglog" target="_blank" rel="noreferrer">
                  blog
                </a>
              </li>
              <li className="gitlab">
                <a href="https://gitlab.com/farmos_public" target="_blank" rel="noreferrer">
                  gitlab
                </a>
              </li>
              {/* <li className="shop">
                <a href="http://smartstore.naver.com/farmos">Shop</a>
              </li> */}
              <li className="member">
                <a href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/farmos0?ChkDispType=1">동료영입</a>
              </li>
            </ul>
          </div>

          <ul className="menu">
            <li>
              <a onClick={() => setIsmenu(false)} href="/">
                회사소개
              </a>
            </li>
            <li>
              <a onClick={() => setIsmenu(false)} href="/product">
                제품소개
              </a>
            </li>
            <li>
              <a onClick={() => setIsmenu(false)} href="/notice_list">
                공지사항
              </a>
            </li>
            <li>
              {/* <a href="#modal_inquiry">문의하기</a> */}
              <a style={{ cursor: "pointer" }} onClick={modalShow}>
                문의하기
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
