import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isModalAtom } from "./atoms";
import EmailSend from "./Component/EmailSend";
import Access from "./routes/Access";
import Home from "./routes/Home";
import Login from "./routes/Login";
import Notice from "./routes/Notice";
import NoticeEdit from "./routes/NoticeEdit";
import NoticeUser from "./routes/NoticeUser";
import Product from "./routes/Product";

function Router() {
  const isModal = useRecoilValue(isModalAtom);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/product" element={<Product />}></Route>
        <Route path="/notice_list" element={<Notice />}></Route>
        <Route path="/access" element={<Access />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/:notice_list/:id" element={<NoticeUser />}></Route>
        <Route path="/notice_list/:id/edit" element={<NoticeEdit />}></Route>
        <Route path="/notice_list/edit" element={<NoticeEdit />}></Route>
        {/* <Route path="/contect" element={<EmailSend />}></Route> */}
      </Routes>
    </BrowserRouter>
  );
}
export default Router;
