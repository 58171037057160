import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { isLoginAtom, isModalAtom } from '../atoms'
import { parseClassName } from 'react-toastify/dist/utils'
function Home() {
  const [isLogin, setLoginSet] = useRecoilState(isLoginAtom)
  const [isModal, setModal] = useRecoilState(isModalAtom)

  return (
    <div>
      <div
        id='container'
        className={`container ${isModal ? 'modal-open' : ''}`}
      >
        <div id='contents'>
          <div className='commpany_box'>
            <div className='commpany_in'>
              <h2>
                <span>FarmOS is</span>
              </h2>
              <p className='txt1'>
                스마트하게 농사를 지을 수 있는 테크놀로지를 제공합니다.
              </p>
              <ul>
                <li>
                  <img
                    src={require('../img/contents/img_commpany_01.png')}
                    alt=''
                  />
                  <span>대한민국 스마트팜 표준리딩</span>
                </li>
                <li>
                  <img
                    src={require('../img/contents/img_commpany_01_2.png')}
                    alt=''
                  />
                  <span>쉽고 간편한 UFC 서비스</span>
                </li>
                <li>
                  <img
                    src={require('../img/contents/img_commpany_01_3.png')}
                    alt=''
                  />
                  <span>스마트팜 플랫폼 오픈소스 생태계 기여</span>
                </li>
              </ul>
              <div style={{ float: 'left', width: '33%' }}>
                <a
                  href='docs/FarmOS.pdf'
                  download='파모스_회사소개서'
                  className='btn_down'
                  target='_self'
                >
                  회사소개자료 다운로드
                </a>
              </div>
              <div style={{ float: 'left', width: '33%' }}>
                <a
                  href='docs/HouseNavi.pdf'
                  download='제품소개서_온실내비'
                  className='btn_down'
                  target='_self'
                >
                  온실내비 제품소개서
                </a>
              </div>
              <div style={{ float: 'left', width: '33%' }}>
                <a
                  href='docs/SupplyNavi.pdf'
                  download='제품소개서_관수내비'
                  className='btn_down'
                  target='_self'
                >
                  관수내비 제품소개서
                </a>
              </div>
            </div>
          </div>
          <div className='commpany_box2 '>
            <div className='commpany_in'>
              <h2>
                <span>Mission</span>
              </h2>
              <p className='txt1'>
                농업 종사자에게 정보기술을 제공하여 작물을 더 잘 재배하도록
                돕는다
              </p>
              <p className='txt2'>
                We help farmers and growers to grow crops better by providing
                information and communications technology
              </p>
              <div className='bar'></div>
              <h2>
                <span>Vision</span>
              </h2>
              <p className='txt1'>
                대한민국 베스트 프랙티스 (Best Practice) 농장 환경제어시스템
                공급자
              </p>
              <p className='txt2'>
                Best practice farm environment control system provider of Korea
              </p>
              <div className='bar'></div>
              <h2>
                <span>History</span>
              </h2>
              <div className='history'>
                <dl>
                  <dt>2022</dt>
                  <dd>
                    <strong>FarmOS Ver 3.0 </strong>
                    UFC(User Friendly Control) 컨셉으로 리뉴얼 <br />
                    크로스 플랫폼 지원(제어판넬 패드 등)
                  </dd>
                </dl>
                <dl>
                  <dt>2021</dt>
                  <dd>
                    <strong>
                      주식회사 파모스 법인 설립
                      <br />
                      FarmOS Ver 2.5
                    </strong>
                    DP(Data Platform), 작기 중심으로 DataSet 관리 <br />
                    데이터 공유, 데이터 분석 기능 구현
                  </dd>
                </dl>
                <dl>
                  <dt>2020</dt>
                  <dd>
                    <strong>FarmOS Ver 2.0</strong>
                    KS 표준 지원, 양액공급기 연동 지원 <br />
                    Rule Engine 기능 추가
                  </dd>
                </dl>
                <dl>
                  <dt>2018</dt>
                  <dd>
                    <strong>FarmOS Ver 1.0</strong>
                    TTA 단체표준 지원, 온실환경관리 SW 오픈소스 공개
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className='commpany_box3'>
            <div className='commpany_in'>
              <h2>
                <span>Partners</span>
              </h2>
              <p className='txt1'>
                정부, 연구기관 및 AgTech 기업과 함께 농업의 발전을 위해 노력하고
                있습니다.
              </p>
              <ul>
                <li>
                  <a
                    href='http://jinong.co.kr/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_01.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.kist.re.kr/kist_web/main/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_02.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='http://atc.hscity.go.kr/index.jsp'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_03.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.ingsys.co.kr/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_04.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.etri.re.kr/intro.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_05.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.farmhannong.com/kor/main/index.do'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_06.png')}
                      alt=''
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.rda.go.kr/main/mainPage.do'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={require('../img/contents/img_partner_07.png')}
                      alt=''
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='commpany_box4'>
            <div className='commpany_in'>
              <h2>
                <span>Open Source</span>
              </h2>
              <p className='txt1'>
                파모스에서 수행한 오픈소스 프로젝트를 확인해보세요.
              </p>
              <ul>
                <li>
                  <a
                    href='https://gitlab.com/farmos_public/foodjukebox'
                    target='blank'
                  >
                    <img
                      src={require('../img/contents/img_source_01.png')}
                      alt=''
                    />
                    푸드쥬크박스{' '}
                    <span>
                      보급형 식물재배 시스템
                      <br /> 구동을 위한 소프트웨어
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href='https://gitlab.com/farmos_public/ksx3267-devspec-inspector'
                    target='blank'
                  >
                    <img
                      src={require('../img/contents/img_source_02.png')}
                      alt=''
                    />
                    장비규격정의서 검사기{' '}
                    <span>
                      KS X 3286 기반의
                      <br /> 장비규격정의서 검사기
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href='https://gitlab.com/farmos_public/sample-default-node'
                    target='blank'
                  >
                    <img
                      src={require('../img/contents/img_source_03.png')}
                      alt=''
                    />
                    센서/구동기 노드{' '}
                    <span>
                      KS X 3267 레퍼런스
                      <br /> 센서/구동기 노드{' '}
                    </span>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <img
                      src={require('../img/contents/img_source_04.png')}
                      alt=''
                    />
                    FarmOS Ver 3 <span>파모스 UFC 서비스 준비중</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='commpany_box5'>
            <h2>
              <span>찾아오시는 길</span>
            </h2>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.899690300645!2d126.95637377634951!3d37.3922044341047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5e1c0dac13e5%3A0x8d4c6922a78a32a8!2z6rK96riw64-EIOyViOyWkeyLnCDrj5nslYjqtawg7Iuc66-864yA66GcMjQ467KI6ri4IDI1!5e0!3m2!1sko!2skr!4v1689224932096!5m2!1sko!2skr'
              width='100%'
              height='600'
              allowFullScreen
              loading='lazy'
              style={{ width: '100%', border: 0 }}
            ></iframe>
            <div className='commpany_in'>
              <h3>㈜파모스</h3>
              <div className='map_1'>
                <p className='txt1'>
                  <span>주소</span> 경기도 안양시 동안구 시민대로248번길 25,
                  503호 (관양동, 경기창조산업안양센터)
                </p>
                <p className='txt2'>
                  <span>전화번호</span> 031-360-4152
                </p>
                <p className='txt3'>
                  <span>팩스</span> 0507-1798-8220
                </p>
              </div>
              <div className='map_2'>
                <h4>지하철 이용 시</h4>
                <p>
                  <span className='num2'>4</span> 호선 평촌역{' '}
                  <strong>1번</strong> 출구(625m, 도보 10분)
                </p>
                <p>
                  <span className='num2'>4</span> 호선 범계역{' '}
                  <strong>7번</strong> 출구(882m, 도보 14분)
                </p>
              </div>
              <div className='map_3'>
                <h4>버스 이용 시</h4>
                <ul>
                  <li>
                    <p>중앙공원(봄빛병원 방면)</p>
                    <div className='bus1'>
                      <span>시내버스</span> 5-1
                    </div>
                  </li>
                  <li>
                    <p>안양시청(법원검찰청,평촌역 방면)</p>
                    <div className='bus1'>
                      <span>시내버스</span> 6, 33, 83
                    </div>
                    <div className='bus2'>
                      <span>직행버스</span>333, 1303, 3330
                    </div>
                  </li>
                  <li>
                    <p>봄빛병원(범계역 방면)</p>
                    <div className='bus1'>
                      <span>시내버스</span>5-1, 10-1
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home
