import React, { useEffect, useState } from "react";
import "fullpage.js/vendors/scrolloverflow";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";
import $ from "jquery";
import { isMobile } from "react-device-detect";

import "fullpage.js/vendors/scrolloverflow.min";
import "fullpage.js/dist/jquery.fullpage.min";

// JQuery 사용 $
export default function Fullscreen(): JSX.Element {
  const [hover, setHover] = useState(1);
  // mobile로 접근 시 fulepage 사용 안함.
  !isMobile &&
    $((): void => {
      $("#fullpage").fullpage({
        scrollOverflow: true,
        anchors: [
          "firstPage",
          "secondPage",
          "3rdPage",
          "4rdPage",
          "5rdPage",
          "6rdPage",
          "lastPage",
        ],
        css3: true,
        navigation: false,
        verticalCentered: false,
        menu: "#menu",
      });
    });

  return (
    <div id="fullpage">
      <div className="section" id="section0">
        <div className="farmosis_box">
          <h2>
            <span>FarmOS is</span>
          </h2>
          <p className="txt1">
            파모스는 작물을 이해하고 관리하는 농장 환경제어 시스템입니다.
          </p>
          <p className="txt2">
            농장에 설치된 센서/구동기로부터 수집되는 데이터와
            <br className="hide_pc" /> CCTV 영상 정보를 토대로 작물의 생육
            상황을 이해하며,
            <br className="hide_pc" /> 이를 통해 작물을 중심으로 농장 환경을
            자동으로 조절합니다.
          </p>
          <ul>
            <li data-menuanchor="secondPage">
              <a href="#secondPage">
                표준 장치 연동<span>Smartfarm Standard</span>
              </a>
            </li>
            <li data-menuanchor="3rdPage">
              <a href="#3rdPage">
                데이터 수집<span>Data Collection</span>
              </a>
            </li>
            <li data-menuanchor="4rdPage">
              <a href="#4rdPage">
                환경제어<span>Environment Control</span>
              </a>
            </li>
            <li data-menuanchor="5rdPage">
              <a href="#5rdPage">
                편리한 작동규칙<span>Convenient Rule</span>
              </a>
            </li>
            <li data-menuanchor="6rdPage">
              <a href="#6rdPage">
                작동규칙 저작 도구<span>Rule Development Kit</span>
              </a>
            </li>
            <li data-menuanchor="lastPage">
              <a href="#lastPage">
                데이터 플랫폼<span>Data Flatform</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="section" id="section1">
        <div className="product_box1">
          <div className="product_in">
            <h2>
              <span>표준 장치 연동</span>
            </h2>
            <p className="txt1">
              스마트팜 표준을 리딩하는 레퍼런스 제품,
              <br className="hide_pc" /> 파모스를 사용해서 서로 다른 회사의
              제품들을
              <br className="hide_pc" /> 연동해보세요.
            </p>
            <p className="txt2">
              스마트온실과 관련된 KS 국가표준, TTA 단체표준을 지원함으로써
              <br className="hide_pc" /> 서로 다른 회사에서 만들어졌더라도,
              표준화된 제품 간에
              <br className="hide_pc" /> 연동이 가능하도록 지원합니다.
            </p>
            <div className="motion_p1">
              <img
                src={require("../img/contents/img_product_01.jpg")}
                alt="FarmOS - A회사 센서, B회사 센서, C사 구동기, D회사 구동기"
              />
            </div>
            <h3>지원하는 표준 자세히 보기</h3>
            <ul>
              <li
                onMouseOver={() => setHover(1)}
                className={hover === 1 ? "on" : undefined}
              >
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2282&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3265&sortOrder="
                  target="_blank"
                  className="t"
                >
                  KS X 3265
                </a>
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2282&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3265&sortOrder="
                  target="_blank"
                  className="link"
                >
                  스마트 온실을 위한 구동기 인터페이스
                </a>
              </li>
              <li
                onMouseOver={() => setHover(2)}
                className={hover === 2 ? "on" : undefined}
              >
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2283&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3266&sortOrder="
                  target="_blank"
                  className="t"
                >
                  KS X 3266
                </a>
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2283&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3266&sortOrder="
                  target="_blank"
                  className="link"
                >
                  스마트 온실을 위한 센서 인터페이스
                </a>
              </li>
              <li
                onMouseOver={() => setHover(3)}
                className={hover === 3 ? "on" : undefined}
              >
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2284&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3267&sortOrder="
                  target="_blank"
                  className="t"
                >
                  KS X 3267
                </a>
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=2284&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3267&sortOrder="
                  target="_blank"
                  className="link"
                >
                  스마트 온실 센서구동기 노드 및 온실 통합 제어기 간 RS485 기반
                  모드버스 인터페이스
                </a>
              </li>
              <li
                onMouseOver={() => setHover(4)}
                className={hover === 4 ? "on" : undefined}
              >
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=5031&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3286&sortOrder="
                  target="_blank"
                  className="t"
                >
                  KS X 3286
                </a>
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=5031&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3286&sortOrder="
                  target="_blank"
                  className="link"
                >
                  RS485 모드버스 기반 스마트 온실 노드 디바이스 등록 절차 및
                  기술 규격
                </a>
              </li>
              <li
                onMouseOver={() => setHover(5)}
                className={hover === 5 ? "on" : undefined}
              >
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=5033&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3288&sortOrder="
                  target="_blank"
                  className="t"
                >
                  KS X 3288
                </a>
                <a
                  href="https://www.rra.go.kr/ko/reference/kcsList_view.do?nb_seq=5033&cpage=1&nb_type=6&searchCon=nb_model&searchTxt=3288&sortOrder="
                  target="_blank"
                  className="link"
                >
                  스마트 온실의 온실 통합 제어기와 양액기 노드 간 RS485 기반
                  모드버스 인터페이스
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section" id="section2">
        <div className="product_box2">
          <div className="product_in">
            <h2>
              <span>데이터 수집</span>
            </h2>
            <p className="txt1">
              양질의 농장 데이터를 수집하고
              <br className="hide_pc" /> 원하는 정보만 골라 실시간으로
              확인해보세요.
            </p>
            <p className="txt2">
              임베디드 단에서 결측 데이터와 이상 데이터를 보정 및
              <br className="hide_pc" /> 내재화된 데이터 로거 기능을 통해 미전송
              데이터를 관리하고
              <br className="hide_pc" /> 센서 데이터, 제어판넬에서 정확한
              제어상태 데이터를 수집합니다.
            </p>
            <div className="motion_p2">
              <img src={require("../img/contents/img_product_02.png")} alt="" />
            </div>
            <div className="box">
              <div className="box_left">
                <h3>실시간 환경 데이터</h3>
                <p>
                  온실에 설치된 센서로부터
                  <br className="hide_pc" /> 실시간으로 데이터를 수집하여
                  <br className="hide_pc" /> 다양한 차트 형태로 시각화
                </p>
              </div>
              <div className="box_right">
                <h3>구동기 제어 현황</h3>
                <p>
                  장치 단에서 제어 성공/실패 데이터를
                  <br className="hide_pc" /> 수집하고, 정확한 제어상태 정보
                  시각화
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section" id="section3">
        <div className="product_box3">
          <div className="product_in">
            <h2>
              <span>환경 제어</span>
            </h2>
            <p className="txt1">
              어떤 제조사, 어느 제품을 사용하더라도
              <br className="hide_pc" /> 맞춤형 사용자 인터페이스로
              제어해보세요.
            </p>
            <p className="txt2">
              각종 스마트팜 장치 설치 후 파모스와 연결해
              <br className="hide_pc" /> 손쉽게 검색하고 등록할 수 있습니다.
              <br />
              장치의 종류와 레벨에 따라, <br className="hide_pc" />
              사용자 UI를 최적의 화면으로 제공합니다.
            </p>
            <div className="motion_p3">
              <img src={require("../img/contents/img_product_03.png")} alt="" />
              <img
                src={require("../img/contents/img_product_03_2.png")}
                alt=""
              />
            </div>
            <h3>레벨별 구동기</h3>
            <p>
              <span className="t">Level 1</span> On/Off 제어, 시간 지정 제어
            </p>
            <p>
              <span className="t">Level 2</span> On/Off 제어, 시간 지정 제어,
              제어량 지정 제어
            </p>
            <p className="txt">각각의 구동기에 맞는 레벨별 사용자 UI</p>
          </div>
        </div>
      </div>

      <div className="section" id="section4">
        <div className="product_box4">
          <div className="product_in">
            <h2>
              <span>편리한 작동규칙</span>
            </h2>
            <p className="txt1">
              기존의 복잡하고 어려운 환경제어는 그만,
              <br className="hide_pc" /> 쉽고 편하면서도 정밀한 환경제어를
              <br className="hide_pc" /> 경험해보세요.
            </p>
            <p className="txt2">
              환기관리, 냉난방관리, 관수관리 등 기본적인 작동규칙을{" "}
              <br className="hide_pc" /> 제공할뿐만 아니라, 사용하기 어렵던
              복합제어, 비례제어 등도 <br className="hide_pc" /> 터치 몇 번으로
              설정을 끝내는 UFC(User Friendly Control){" "}
              <br className="hide_pc" />
              서비스를 제공하고, 지속적으로 업그레이드 합니다.
            </p>
            <ul>
              <li>
                <div className="motion_p4">
                  <img
                    src={require("../img/contents/img_product_04.png")}
                    alt=""
                  />
                </div>
                <h3>단순 작동규칙</h3>
                <p>
                  기본값 또는 사용자 <br className="hide_pc" /> 설정값에 따라
                  구동기를 <br className="hide_pc" /> 자동으로 제어하는{" "}
                  <br className="hide_pc" /> 단순한 기능의 작동규칙
                </p>
              </li>

              <li>
                <div className="motion_p5">
                  <img
                    src={require("../img/contents/img_product_04_2.png")}
                    alt=""
                  />
                </div>
                <h3>다중 작동규칙</h3>
                <p>
                  다수의 단순 작동규칙이 모여 <br className="hide_pc" /> 작물의
                  생육단계를 스스로 <br className="hide_pc" />
                  판단하고 설정값을 변경하며 <br className="hide_pc" />
                  제어하는 생육단계별 <br className="hide_pc" />
                  맞춤형 작동규칙
                </p>
              </li>

              <li>
                <div className="motion_p6">
                  <img
                    src={require("../img/contents/img_product_04_3.png")}
                    alt=""
                  />
                </div>
                <h3>레시피 작동규칙</h3>
                <p>
                  사용자가 원하는 재배 환경 <br className="hide_pc" />
                  컨셉(에너지 절약, 생산량 <br className="hide_pc" />
                  증대 등)에 따라 온실 내부의 <br className="hide_pc" />
                  전체적인 환경을 자동으로 <br className="hide_pc" />
                  관리하는 작동규칙
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section" id="section5">
        <div className="product_box5">
          <div className="product_in">
            <h2>
              <span>작동규칙 저작 도구</span>
            </h2>
            <p className="txt1">
              강력한 작동규칙 저작 도구를 사용하여
              <br className="hide_pc" /> 자신만의 맞춤형 작동규칙을
              개발해보세요.
            </p>
            <p className="txt2">
              농업 연구자 및 고급 사용자를 위해 사용자가 원하는 대로
              <br className="hide_pc" /> 장비, 데이터, 계산 수식을 적용해 온실을
              맞춤형으로 제어하는
              <br className="hide_pc" /> 강력한 작동규칙 저작도구(RDK: Rule
              Development Kit)를 <br className="hide_pc" /> 제공합니다.
            </p>
            <div className="motion">
              <div className="motion_p7">
                <img
                  src={require("../img/contents/img_product_05.png")}
                  alt=""
                />
              </div>
              <div className="motion_p8">
                <img
                  src={require("../img/contents/img_product_05_2.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section" id="section6">
        <div className="product_box6">
          <div className="product_in">
            <h2>
              <span>데이터 플랫폼</span>
            </h2>
            <p className="txt1">
              축적된 농장 데이터를 기반으로
              <br className="hide_pc" /> 데이터 플랫폼 기능을 활용해보세요.
            </p>
            <p className="txt2">
              파모스에서 제공하는 데이터 플랫폼 기능은
              <br className="hide_pc" /> 작기를 중심으로 데이터셋을 모아주고
              히스토리를 기록하는,
              <br className="hide_pc" /> 농업에 최적화된 빅데이터 관리
              도구입니다.
            </p>
            <div className="motion_p9">
              <img src={require("../img/contents/img_product_06.png")} alt="" />
            </div>
            <ul>
              <li>
                <h3>사용자 권한별 데이터 공유 기능</h3>
                <p>
                  소유자, 위탁관리자, 연구자, 조사자 등{" "}
                  <br className="hide_pc" />
                  사용자 권한별 데이터 공유 및 관리
                </p>
              </li>

              <li>
                <h3>데이터 분석 기능</h3>
                <p>
                  평균, 표준편차 등의 기술통계뿐만 아니라{" "}
                  <br className="hide_pc" />
                  회귀분석 등 다양한 데이터 통계 분석
                </p>
              </li>

              <li>
                <h3>주피터 노트북 연동 기능</h3>
                <p>
                  주피터 노트북 개발환경 내재화로
                  <br className="hide_pc" /> numpy, pandas 등의 python
                  라이브러리를 <br className="hide_pc" />
                  활용한 농장 데이터셋 분석
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section fp-auto-height" id="section7">
        <div className="product_box7" id="pro_7">
          <h2>제품에 대해 더 궁금하신점이 있으신가요?</h2>
          <div className="box">
            <div className="box_left">
              <p>제품 소개서를 통해 궁금한 점을 해결해보세요.</p>
              <a
                href="https://drive.google.com/file/d/1UbLCvO56CorcjRv8eHUHzIuCSD3Du4hl/view?usp=sharing"
                target="_blank"
              >
                제품소개자료 다운로드
              </a>
            </div>
            <div className="box_right" onClick={() => alert("준비 중입니다")}>
              <p>추가적인 궁금점에 대해 파모스에서 자세하게 답변드립니다.</p>
              <a href="#">문의하기</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
