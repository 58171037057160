import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Router from "./Router";
import { ReactQueryDevtools } from "react-query/devtools";
import { darkTheme, lightTheme } from "./theme";
import { isDarkAtom, isModalAtom } from "./atoms";
import { useRecoilValue } from "recoil";
import Header from "./Component/Header";

import "./css/contents_v1.css";
// import "./css/contents.css";
import "./css/fullPage.css";
import "./css/jquery.modal.min.css";
import "./css/reset.css";
import './css/editor.css'
import Footer from "./Component/Footer";
import EmailSend from "./Component/EmailSend";

// react 쓸데없는 padding이랑 margin 제거
const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	letter-spacing: -0.035em;
	font-family:'NotoKr',sans-serif;
	font-weight:100;
	color: #666;
}
`;

// useRecoilValue를 사용해서 recoil의 값 (state) 불러오기

function App() {
  const isModal = useRecoilValue(isModalAtom);
  const isDark = useRecoilValue(isDarkAtom);
  return (
    <>
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        <GlobalStyle />
        <Header />
        {isModal ? <EmailSend /> : null}
        <Router />
        <Footer />
        <ReactQueryDevtools initialIsOpen={true} />
      </ThemeProvider>
    </>
  );
}

export default App;
