import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isLoginAtom } from "../atoms";
import { useEffect, useState } from "react";
import { fetchLogin } from "./api";
import Swal from "sweetalert2";

interface Login {
  success: boolean;
  user: string;
  id: number;
}

function Login() {
  const [isLogin, setLoginSet] = useRecoilState(isLoginAtom);
  const navigate = useNavigate();
  const [user_id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { data, remove, refetch } = useQuery<Login>(
    ["login"],
    () => fetchLogin({ user_id, password }),
    {
      enabled: false,
    }
  );

  const submit = () => {
    if (user_id !== "" && password !== "") {
      refetch();
    } else {
      Swal.fire({
        icon: "warning",
        title: "로그인 오류",
        text: "아이디 비밀번호를 입력해 주세요.",
      });
    }
  };

  useEffect(() => {
    console.log(data);
    if (isLogin.access === true) {
      Swal.fire({
        icon: "success",
        title: "로그인 성공",
        text: "이미 로그인 되어 있습니다.",
      }).then(e => {
        if (e.value) navigate("/");
      });
    } else if (data?.success === true) {
      setLoginSet({ access: true, user: data.user, id: data.id });
      Swal.fire({
        icon: "success",
        title: "로그인 성공",
        text: "로그인 성공했습니다.",
      }).then(e => {
        if (e.value) navigate("/");
      });
    } else if (data?.success === false) {
      Swal.fire({
        icon: "warning",
        title: "로그인 실패",
        text: "아이디 비밀번호가 틀립니다.",
      }).finally(() => {
        remove();
      });
    }
  }, [data]);

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  return (
    <>
      <div id="container" className="container">
        <div id="contents">
          <div className="login_box">
            <h2>
              <span>로그인</span>
            </h2>
            <legend>로그인</legend>
            <fieldset className="login_in">
              <legend>로그인</legend>
              <input
                value={user_id}
                onChange={e => setId(e.target.value)}
                type="text"
                className="txt"
                title="아이디"
                placeholder="아이디"
                onKeyPress={onKeyPress}
              />
              <input
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                className="txt"
                title="비밀번호"
                placeholder="비밀번호"
                onKeyPress={onKeyPress}
              />
              <button type="submit" className="login" onClick={submit}>
                로그인
              </button>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
