import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { fetchPostText, fetchPutText, fetchViewText } from "./api";
import { useRecoilValue } from "recoil";
import { isLoginAtom } from "../atoms";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IFetch, IFIle } from "../ts/Interface";

interface ILocation {
  state?: {
    item: {
      text_id: number;
      text_title: string;
      text_content: string;
      update: string;
      count: number;
      id: number;
      deleted: number;
      board_name: string;
      user_id: string;
      path: string | null;
      text_type: string;
      type_name: string;
    };
    board_id: number;
  };
}

interface IPost {
  success: boolean;
  id: number;
}

const NoticeEdit = () => {
  const data = new FormData();
  const { state } = useLocation() as ILocation;
  const navigation = useNavigate();
  const login = useRecoilValue(isLoginAtom);
  const [isFile, setIsFile] = useState<IFIle | string | null>();
  const [isTitle, setIsTitle] = useState<string>("");
  const [isContent, setIsContent] = useState<string>("");
  const [isType, setIsType] = useState<string>("010");

  const [edit, setEdit] = useState<boolean>(false);

  const {
    data: msg,
    remove,
    refetch,
  } = useQuery<IPost>(
    ["Post", edit],
    () =>
      edit ? fetchPutText(state?.item.text_id, data) : fetchPostText(data),
    {
      enabled: false,
    }
  );

  const onFileChange = (e: any) => {
    // data.append("file", e.target.files[0]);
    setIsFile(e.target.files[0]);
  };

  const backHistory = () => {
    navigation(-1);
  };

  useEffect(() => {
    if (state?.item !== undefined) {
      setIsTitle(state?.item.text_title);
      setIsContent(state?.item.text_content);
      setIsType(state?.item.text_type);
      setIsFile(state?.item.path);
      setEdit(true);
    }
  }, []);

  const PostNotice = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    data.append("text_title", isTitle);
    data.append("text_content", isContent);
    data.append("deleted", "0");
    data.append("board_id", state?.board_id as any);
    data.append("id", login.id);
    data.append("text_type", String(isType));
    if (edit) {
      if (typeof isFile === "object") {
        data.append("upload", isFile as any);
      } else {
        data.append("upload", "");
      }
    } else {
      data.append("upload", isFile as any);
    }
    Swal.fire({
      icon: "info",
      title: "게시글 등록",
      text: `게시글 ${edit ? "수정" : "생성"} 하시겠습니까?`,
      showCancelButton: true,
    }).then(_e => {
      if (_e.isConfirmed) {
        refetch();
        // e.currentTarget.disabled = true;
      }
    });
  };

  useEffect(() => {
    if (msg?.success === true) {
      Swal.fire({
        icon: "success",
        title: "게시글 등록",
        text: `게시글 등록하였습니다.`,
      }).then(e => {
        if (e.isConfirmed) {
          remove();
          navigation("/notice_list");
        }
      });
    }
  }, [msg]);

  return (
    <>
      <div id="container" className="container">
        <div id="contents">
          <div className="notice_box">
            <h2 className="title">
              <span>공지사항</span>
            </h2>
            <div className="notice_write2">
              <dl className="t">
                <dt>구분</dt>
                <dd>
                  <label className="check_ty1">
                    <input
                      value={isType}
                      id="1"
                      type="radio"
                      checked={isType === "010"}
                      onChange={() => setIsType("010")}
                    />
                    <span>공지사항</span>
                  </label>
                  <label className="check_ty1">
                    <input
                      value={isType}
                      id="2"
                      type="radio"
                      checked={isType === "011"}
                      onChange={() => setIsType("011")}
                    />
                    <span>업데이트 노트</span>
                  </label>
                  {/* <label className="check_ty1">
								<input type="checkbox" /> <span>공지사항</span>
							</label>
							<label className="check_ty1">
								<input type="checkbox" /> <span>업데이트 노트</span>
							</label> */}
                </dd>
              </dl>
              <dl>
                <dt>제목</dt>
                <dd>
                  <input
                    value={isTitle}
                    onChange={e => setIsTitle(e.target.value)}
                    type="text"
                    className="inp_ty2 "
                    style={{ width: "100%" }}
                    title="제목"
                  />
                </dd>
              </dl>
              <dl>
                <dt>내용</dt>
                <dd>
                  <div className="data_box" style={{ width: "100%" }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={isContent}
                      config={{
                        toolbar: [
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "blockQuote",
                          "insertTable",
                          "undo",
                          "redo",
                        ],
                        placeholder: "글을 입력해보세요!",
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setIsContent(data);
                      }}
                    />
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>파일</dt>
                <dd>
                  <div className="file_box">
                    {isFile !== undefined ? (
                      <input
                        value={
                          typeof isFile === "object" ? isFile?.name : isFile
                        }
                        className="inp_ty2"
                        id="file_add"
                        title="첨부파일명"
                        type="text"
                        placeholder=""
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <input
                        value={""}
                        className="inp_ty2"
                        id="file_add"
                        title="첨부파일명"
                        type="text"
                        disabled
                        style={{ width: "100%" }}
                      />
                    )}
                    <div className="file_btn">
                      <input
                        onChange={onFileChange}
                        title="파일첨부"
                        type="file"
                      />
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
            <div className="btn_center">
              <button onClick={backHistory} type="button" className="btn st2">
                취소
              </button>
              <button
                disabled={false}
                onClick={PostNotice}
                type="submit"
                className="btn"
              >
                {edit ? "수정" : "등록"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeEdit;
