import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchNoticeBoard, fetchViewText } from "./api";
import Pagination from "../Component/Pagination";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { isLoginAtom } from "../atoms";
import styled from "styled-components";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import NoticeList from "../Component/NoticeList";

interface Board {
  text_id: number;
  text_title: string;
  text_content: string;
  update: string;
  count: number;
  id: number;
  deleted: number;
  board_name: string;
  user_id: string;
  type_name: string;
}

const Containor = styled.div`
  width: 100vw;
  height: 80vh;
  padding: 100px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notice = () => {
  const [type, setType] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;
  const [isSearch, setIsSearch] = useState<string>("");
  const navigation = useNavigate();
  const login = useRecoilValue(isLoginAtom);
  console.log(offset, limit);
  const { isLoading, data } = useQuery<Board[]>(["Board"], () =>
    fetchNoticeBoard()
  );

  const goNewPost = () => {
    if (login.access !== false) {
      navigation("edit", { state: { board_id: 1 } });
    } else {
      Swal.fire({
        icon: "warning",
        title: "권한 에러",
        text: "로그인이 되어있지 않습니다.",
      }).then(e => {
        if (e.value) navigation("/login");
      });
    }
  };

  if (isLoading) {
    return (
      <Containor>
        <ReactLoading
          type="spokes"
          color="rgb(53, 126, 221)"
          height={"3%"}
          width={"3%"}
        />
      </Containor>
    );
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSearch(e.target.value);
  };

  return (
    <>
      <div id="container" className="container">
        <div id="contents">
          <div className="notice_box">
            <h2 className="title">
              <span>공지사항</span>
            </h2>
            <div className="search_box">
              {/* <select
                className="select1"
                style={{ width: "150px" }}
                title="옵션선택"
              >
                <option value="">옵션선택</option>
              </select> */}
              <input
                value={isSearch}
                onChange={onChangeSearch}
                type="text"
                title="검색어 입력"
                className="inp"
              />
              <button type="submit" className="btn_search">
                검색
              </button>
            </div>
            <div className="notice_list">
              <table>
                <colgroup>
                  <col style={{ width: "95px" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "190px" }} />
                  <col style={{ width: "120px" }} />
                  <col style={{ width: "100px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>제목</th>
                    <th>등록일</th>
                    <th>작성자</th>
                    <th>조회수</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {data
                    ?.filter(item => item.text_title.includes(isSearch))
                    .slice(offset, offset + limit)
                    .map((item, index, array) => (
                      <NoticeList
                        key={item.text_id}
                        num={array.length - index}
                        item={item}
                        board_id={1}
                      />
                    ))}
                </tbody> */}
              </table>
            </div>
            <div className="notice_list2">
              {/* <tbody> */}
              {type
                ? data
                    ?.filter(item => item.text_title.includes(isSearch))
                    .slice(offset, offset + limit)
                    .map((item, index, array) => (
                      <NoticeList
                        key={item.text_id}
                        num={array.length - index}
                        item={item}
                        board_id={1}
                      />
                    ))
                : data
                    ?.filter(item => item.text_title.includes(isSearch))
                    .slice(0, offset + limit)
                    .map((item, index, array) => (
                      <NoticeList
                        key={item.text_id}
                        num={array.length - index}
                        item={item}
                        board_id={1}
                      />
                    ))}
              {/* </tbody> */}
            </div>
            <div className="btn_center m">
              <button
                onClick={() => {
                  setPage(page + 1);
                  setType(false);
                }}
                type="button"
                className="btn m_w100"
              >
                더보기
              </button>
            </div>
            <div className="btn_center">
              {login.access ? (
                <button
                  onClick={goNewPost}
                  type="button"
                  className="btn m_w100"
                >
                  글쓰기
                </button>
              ) : null}
            </div>
            <Pagination
              total={
                data?.filter(item => item.text_title.includes(isSearch)).length
              }
              page={page}
              setPage={setPage}
              limit={limit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notice;
