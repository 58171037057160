import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
interface Board {
  item: {
    text_id: number;
    text_title: string;
    text_content: string;
    update: string;
    count: number;
    id: number;
    deleted: number;
    board_name: string;
    user_id: string;
    type_name: string;
  };
  board_id: number;
  num: number;
}

const NoticeList = ({ item, num, board_id }: Board) => {
  return (
    <>
      {/* <tr key={item.text_id}>
        <td>{num}</td>
        <td className="left">
          <Link
            to={`/notice_list/${item.text_id}`}
            state={{ item: item, board_id: board_id }}
          >
            [{item.type_name}] {item.text_title}
          </Link>
        </td>
        <td>{moment(item.update).format("YYYY-MM-DD HH:mm:ss")}</td>
        <td>{item.user_id}</td>
        <td>{item.count}</td>
      </tr> */}

      <p key={item.text_id}>
        <span className="t1">{num}</span>
        <span className="t2">
          <Link
            to={`/notice_list/${item.text_id}`}
            state={{ item: item, board_id: board_id }}
          >
            <span>[{item.type_name}]</span> {item.text_title}
          </Link>
        </span>
        <span className="t3">
          {moment(item.update).format("YYYY-MM-DD HH:mm:ss")}
        </span>
        <span className="t4">{item.user_id}</span>
        <span className="t5">{item.count}</span>
      </p>
    </>
  );
};

export default NoticeList;
