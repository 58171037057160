import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isModalAtom } from "../atoms";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { fetchSendEmail } from "../routes/api";
import Swal from "sweetalert2";

const Box = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 10px;
`;

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

interface data {
  success: boolean;
}

const EmailSend = () => {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [isModal, setModal] = useRecoilState(isModalAtom);
  const { data, remove, refetch } = useQuery<data>(
    ["send"],
    () =>
      fetchSendEmail({
        asking_user: user,
        asking_email: email,
        asking_content: content,
      }),
    {
      enabled: false,
    }
  );

  const sendEmail = () => {
    var regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (user === "" || email === "" || content === "") {
      Swal.fire({
        icon: "warning",
        title: "공백 에러",
        text: `이름, 이메일, 내용을 입력해주세요.`,
      });
    } else if (regEmail.test(email) !== true) {
      Swal.fire({
        icon: "warning",
        title: "이메일 에러",
        text: "유효한 이메일이 아닙니다.",
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "메일 전송",
        text: `메일 전송 하시겠습니까?`,
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          refetch();
        }
      });
    }
  };

  useEffect(() => {
    if (data?.success === true) {
      Swal.fire({
        icon: "success",
        title: "메일 전송",
        text: `메일 전송하였습니다.`,
      }).then((e) => {
        if (e.isConfirmed) {
          remove();
          setModal(!isModal);
        }
      });
    }
  }, [data]);

  // isModal 이 true인 경우 scroll 막기
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModal]);

  return (
    <>
      <div
        id="contents"
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1002,
        }}
        onClick={() => setModal(false)}
      >
        <Box
          initial="hidden"
          animate="visible"
          variants={variants}
          id="modal_inquiry"
          className="modal"
          onClick={(e) => e.stopPropagation()}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1003",
            padding: "2rem",
          }}
        >
          <h1 className="title">
            <span>문의하기</span>
          </h1>
          <p className="inquiry_txt">문의사항을 남겨주세요.</p>
          <div className="inquiry_box">
            <div className="line1">
              <label>
                이름<span>*</span>을 입력하세요.
              </label>
              <input type="text" id="inquiry_t1" title="이름을 입력하세요" className="inp_ty1" value={user} onChange={(e) => setUser(e.target.value)} />
            </div>
            <div className="line2">
              <label>
                이메일<span>*</span>을 입력하세요.
              </label>
              <input type="text" id="inquiry_t2" title="이메일을 입력하세요" className="inp_ty1" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="line1">
              <label>무엇이 궁금하신가요?</label>
              <textarea className="data_box" id="inquiry_t3" style={{ height: "202px" }} value={content} onChange={(e) => setContent(e.target.value)}></textarea>
            </div>
            <div className="btn_center">
              <button className="btn" onClick={sendEmail}>
                문의사항 접수하기
              </button>
            </div>
          </div>
          <input type="button" className="close_btn_3" onClick={() => setModal(!isModal)} />
        </Box>
      </div>
    </>
  );
};

export default EmailSend;
